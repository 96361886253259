<template>
    <section class="my-2">
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 pt-2 pb-3 br-t-12 align-items-center bg-white px-5 text-general f-18 f-600">
            <div class="col-auto my-xl-0 my-lg-3 my-md-3 my-sm-3 px-0">
                Ventas y pedidos día a día
            </div>
            <div class="col-xl col-lg-10 px-lg-0 px-md-0 ml-xl-0 ml-lg-auto ml-md-auto md-sm-auto">
                <div class="row mx-xl-5 mx-lg-0 mx-md-0 justify-content-xl-start justify-content-lg-between border-bottom">
                    <div
                    v-for="(data, i) in meses" :key="i"
                    class="col-auto p-2 f-14" :class="data.activo ? (mes == data.fecha ? 'cr-pointer border-b-red text-gr-red' :'cr-pointer text-muted2') :'text-gris' "
                    @click="seleccionarMes(data)"
                    >
                        {{ data.mes }}
                    </div>
                </div>
            </div>
        </div>
        <div class="position-relative">
            <cargando v-if="loading1" />
            <chartBarras :datos="datos_diarios" :chart="chart_diario" />
        </div>
        <!-- Grafica Ventas y Pedidos dia a dia -->
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 px-5 text-general mt-4 py-2 bg-white br-t-12 f-18 f-600">
            Ventas mensuales durante los últimos 12 meses
        </div>
        <div>
            <cargando v-if="loading2" />
            <chartBarras :datos="datos_mensual" :chart="chart_meses" />
        </div>
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 px-5 text-general f-18 f-600 mt-4 pt-3 br-t-12 bg-white">
            Ticket promedio de los últimos 12 meses
        </div>
        <!-- Calificaciones pedidos permisos 12 -->
        <div class="position-relative">
            <cargando v-if="loading2" />
            <chartBarras :datos="datos_ultimos_promedio" :chart="chart_meses_promedio" />
        </div>
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 px-5 text-general f-18 f-600 pt-3 br-t-12 mt-4 bg-white">
            Calificaciones de los pedidos de los últimos 12 meses
        </div>
        <!-- Calificaciones pedidos permisos 12 -->
        <div>
            <cargando v-if="loading3" />
            <chartBarras :datos="datos_ultimos" :chart="chart_ultimos" />
        </div>
    </section>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import echarts from 'echarts';
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-db');
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-general');
import MetricasTeams from "~/services/metricas_teams"
export default {
    props: {
        datos : {
            type: Boolean,
            default: true
        },
        tipoFecha : {
            type: Number,
            default: 1
        }
    },
    data(){
        return{
            loading1: true,
            loading2: true,
            loading3: true,
            mes: '',
            meses: [],
            datos_mensual: [
                { nombre: 'Ventas totales', valor: '$25.564.654', color: 'bg-gr-general', formato:1 },
                { nombre: 'Ventas promedio al mes', valor: '$234.500', color: 'bg-gr-pink', formato:1 },
                { nombre: 'Pedidos totales', valor: '1.542', color: 'bg-gr-red', formato:2 },
                { nombre: 'Pedidos promedio al mes', valor: '84', color: 'bg-gr-purple', formato:2 }
            ],
            datos_diarios: [
                { nombre: 'Ventas totales', valor: '$25.564.654', color: 'bg-gr-red', formato:1 },
                { nombre: 'Ventas promedio al día', valor: '$234.500', color: 'bg-gr-general', formato:1 },
                { nombre: 'Día de mayor venta', valor: '$287.000', fecha: '12 de Noviembre', color: 'bg-dark', formato:1 }
            ],
            datos_ultimos: [
                { nombre: 'Mes con mejor calificiación', valor: '0', fecha: 'Ene. 2020', color: 'bg-gr-general', formato:2 },
                { nombre: 'Mes con peor calificiación', valor: '0', fecha: 'Oct. 2019', color: 'bg-gr-red', formato:2 }
            ],
            datos_ultimos_promedio: [
                { nombre: 'Ticket promedio', valor: '0', color: 'bg-gr-general', formato: 1 },
                { nombre: 'Mes con Ticket promedio más alto', valor: '0', fecha: 'Oct. 2019', color: 'bg-gr-red', formato: 1 }
            ],
            chart_meses_promedio: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['mes', 'promedio'],
                    source: []
                },
                series: [
                    {
                        name: 'Ticket',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            chart_meses: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    },
                    {
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['mes', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            chart_diario: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    },
                    {
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['dia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            chart_ultimos: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Cantidad', 'Calificaciones']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        name: 'Calificación',
                        type: 'value',
                        scale: true,
                    },
                    {
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['mes', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Calificaciones',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Cantidad',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            ventas_mensuales_created: null,
            ventas_mensuales_entregas: null,
            ventas_diarias_created: null,
            ventas_diarias_entregas: null,
            calificaciones_mensuales_created: null,
            calificaciones_mensuales_entregas: null,
            ids_tipos: [],
            sin_tipo: false,
            leecheros: [],
            id_team: null,
            team: true,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        calcPantalla(){
            if (screen.width < 1400){
                return 4
            }else { return 6 }
        },
    },
    watch: {
        tipoFecha(){
            this.calcularVentasMensuales()
            this.calcularVentasDiarias()
            this.calcularCalificaciones()
        },
    },
    methods: {
        cargando(){
            this.loading1 = true
            this.loading2 = true
            this.loading3 = true
        },
        async montar(ids_tipos, sin_tipo, leecheros, id_team, team ){
            if (this.id_cedis != null){
                this.ids_tipos = ids_tipos
                this.sin_tipo = sin_tipo
                this.leecheros = leecheros
                this.id_team = id_team
                this.team = team

                this.cargando()
                await this.toPromise(this.ventas_mensuales)
                await this.toPromise(this.ventas_diarias)
                await this.toPromise(this.calificaciones_mensuales)
            }
        },
        async ventas_mensuales(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                    leecheros: this.leecheros,
                    id_team: this.id_team,
                    team: this.team
                }
                const { data } = await MetricasTeams.ventas_mensuales(params)
                this.ventas_mensuales_created = data.data.pedidos_created
                this.ventas_mensuales_entregas = data.data.pedidos_entregas

                let datos = this.tipoFecha == 1 ? this.ventas_mensuales_entregas : this.ventas_mensuales_created

                this.meses = datos.pedidos.map(p=>{
                    let m = {}
                    m = {
                        mes: p.mes,
                        activo: p.cantidad > 0,
                        fecha: p.fecha
                    }
                    if (p.cantidad > 0){
                        this.mes = p.fecha
                    }
                    return m
                })

                this.calcularVentasMensuales()

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading2 = false
            }
        },
        calcularVentasMensuales(){
            try {

                let datos = this.tipoFecha == 1 ? this.ventas_mensuales_entregas : this.ventas_mensuales_created
                this.meses = datos.pedidos.map(p=>{
                    let m = {}
                    m = {
                        mes: p.mes,
                        activo: p.cantidad > 0,
                        fecha: p.fecha
                    }
                    // if (p.cantidad > 0){
                    //     // this.mes = p.fecha
                    // }
                    return m
                })
                this.chart_meses.dataset.source = datos.pedidos
                this.datos_mensual[0].valor = datos.total
                this.datos_mensual[1].valor = datos.total_promedio
                this.datos_mensual[2].valor = datos.cantidad
                this.datos_mensual[3].valor = datos.cantidad_promedio

                this.chart_meses_promedio.dataset.source = datos.pedidos
                this.datos_ultimos_promedio[0].valor = datos.promedio
                this.datos_ultimos_promedio[1].valor = datos.max_promedio.promedio
                this.datos_ultimos_promedio[1].fecha = datos.max_promedio.mes

            } catch (error){
                this.error_catch(error)
            }
        },
        async ventas_diarias(){
            try {
                let params = {
                    fecha: this.mes,
                    id_moneda: this.id_moneda,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                    leecheros: this.leecheros,
                    id_team: this.id_team,
                    team: this.team
                }
                const { data } = await MetricasTeams.ventas_diarias(params)
                this.ventas_diarias_created = data.data.pedidos_created
                this.ventas_diarias_entregas = data.data.pedidos_entregas

                this.calcularVentasDiarias()


            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading1 = false
            }
        },
        calcularVentasDiarias(){
            try {

                let datos = this.tipoFecha == 1 ? this.ventas_diarias_entregas : this.ventas_diarias_created
                this.chart_diario.dataset.source = datos.pedidos
                this.datos_diarios[0].valor = datos.total
                this.datos_diarios[1].valor = datos.total_promedio
                this.datos_diarios[2].valor = datos.dia_max ? datos.dia_max.total : 0
                this.datos_diarios[2].fecha = datos.dia_max ? datos.dia_max.dia : 'No registra'

            } catch (error){
                this.error_catch(error)
            }
        },
        async calificaciones_mensuales(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                    leecheros: this.leecheros,
                    id_team: this.id_team,
                    team: this.team
                }
                const { data } = await MetricasTeams.calificaciones_mensuales(params)

                this.calificaciones_mensuales_created = data.data.pedidos_created
                this.calificaciones_mensuales_entregas = data.data.pedidos_entregas

                this.calcularCalificaciones()

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading3 = false
            }
        },
        calcularCalificaciones(){
            try {

                let datos = this.tipoFecha == 1 ? this.calificaciones_mensuales_entregas : this.calificaciones_mensuales_created
                this.chart_ultimos.dataset.source = datos.pedidos
                this.datos_ultimos[0].valor = datos.max ? datos.max.total : 0
                this.datos_ultimos[0].fecha = datos.max ? datos.max.mes : 'No registra'
                this.datos_ultimos[1].valor = datos.min ? datos.min.total : 0
                this.datos_ultimos[1].fecha = datos.min ? datos.min.mes : 'No registra'

            } catch (error){
                this.error_catch(error)
            }
        },
        seleccionarMes(data){
            data.activo ? this.mes = data.fecha : ''
            this.ventas_diarias()
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-icon{
    width: 28px;
    height: 28px;
    background: #000000a3;
}


/* Media Queries */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    .box-left{
        display: flex;
        justify-content: center;
        div{
            width: 335px !important;
            height: 50px !important;
        }
    }
    .box-right{
        display: flex;
        justify-content: center;
        div{
            width: 335px !important;
            height: 50px !important;
        }
    }
}
@media only screen  and (max-width : 1224px) {
    .box-left{
        display: flex;
        justify-content: center;
        padding: 0px !important;
        div{
            display: flex;
            flex-direction: inherit !important;
            padding: 0px !important;
            width: 380px !important;
            height: 50px !important;
        }
    }
    .content-cards{
        div{
            overflow: auto !important;
        }
    }
    .box-right{
        display: flex;
        justify-content: center;
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px !important;
            width: 380px !important;
            height: 50px !important;
        }
    }
}
</style>
